<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <AvatarAddAvatar
                :entity="form"
                :file="form.image"
                @mainImageUploaded="mainImageUploaded"
              />
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem
                prop="type"
                :label="$t('type')"
                @keydown.enter.prevent="submit(ruleFormRef)"
              >
                <ElSelect
                  v-model="form.type"
                  :placeholder="$t('select_a_type')"
                  :autocomplete="true"
                  style="width: 100%"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                >
                  <el-option
                    v-for="item in membershipTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="months" :label="$t('months')">
                <ElInputNumber v-model="form.months" type="price" min="0" />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="price" :label="$t('price')">
                <ElInputNumber
                  v-model="form.price"
                  type="price"
                  step="0.01"
                  min="0"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="currency" :label="$t('currency')">
                <ElSelect
                  v-model="form.currency"
                  :autocomplete="true"
                  :filterable="true"
                  style="width: 100%"
                  :placeholder="$t('select_currency')"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                >
                  <el-option
                    v-for="item in currencies"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem
                prop="personal_travel_concierge"
                :label="$t('personal_travel_concierge')"
              >
                <ElSwitch v-model="form.personal_travel_concierge" />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="description" :label="$t('description')">
                <ElInput
                  v-model="form.description"
                  :autosize="autosize"
                  type="textarea"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_MEMBERSHIP from '~/graphql/memberships/mutation/createMembership.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const form = reactive({
  type: '',
  price: 0,
  currency: 'eur',
  personal_travel_concierge: false,
  description: '',
  months: 0,
  image_id: ''
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()

const ruleFormRef = ref()
const loading = ref(false)
const currencies = getCurrencies()
const membershipTypes = getMembershipTypes()

onMounted(() => {})

const inputRules = reactive({
  type: [
    {
      required: true,
      message: t('type_is_required'),
      trigger: 'blur'
    }
  ],
  price: [
    {
      required: true,
      message: t('price_is_required'),
      trigger: 'blur'
    }
  ],
  months: [
    {
      required: true,
      message: t('months_are_required'),
      trigger: 'blur'
    }
  ]
})

const rules = {
  type: { required },
  price: { required },
  months: { required }
}

const v$ = useVuelidate(rules, form)

const submit = async (formEl) => {
  v$.value.$touch()
    await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
    $showError(t('form_is_invalid'))
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  const variables = {
    type: form.type,
    price: form.price ? parseInt(form.price * 100) : 0,
    currency: form.currency,
    months: form.months,
    personal_travel_concierge: form.personal_travel_concierge,
    description: form.description
  }
  if (form.image_id) {
    variables.image_id = form.image_id
  }
  const response = await mutation(CREATE_MEMBERSHIP, variables)
  if (response.error) {
    if (response.error.message.includes('input.type')) {
      $showError(t('membership_already_exists'))
    } else {
      $showError(response.error, t)
      $sentry(response.error, 'create')
    }
    loading.value = false
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createMembership ?? null)
    loading.value = false
  }
}

const mainImageUploaded = (fileVariables) => {
  form.image_id = fileVariables.file
}
</script>

<style>
</style>